import { Box } from "@chakra-ui/react";
import React from "react";
import { FaCheckCircle } from "react-icons/fa";

const VerifyMark = ({ verify, size }) => {
  return (
    <>
      {verify ? (
        <Box
          as={FaCheckCircle}
          color="blue.500"
          w={`${size}px`}
          h={`${size}px`}
          title="Verified"
        />
      ) : (
        <Box
          as={FaCheckCircle}
          color="gray.400"
          w={`${size}px`}
          h={`${size}px`}
          title="Not Verified"
        />
      )}
    </>
  );
};

export default VerifyMark;
