import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  useToast,
  SimpleGrid,
  Checkbox,
  Text,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import apiRequest from "utils/api";
import { socket } from "socket";

const MessageForm = () => {
  const [formData, setFormData] = useState({
    sectionId: "",
    message: "",
    messageImage: null,
    messageUrl: "",
    delay: 0,
    deleteAfterSend: false,
  });

  const user = useSelector((state) => state.user?.data);
  const toast = useToast(); // For notifications

  const [isBotWork, setIsBotWork] = useState(false);
  const [isBotWorkLoading, setIsBotWorkLoading] = useState(false);
  const [isBotStopLoading, setIsBotStopLoading] = useState(false);

  const [residual, setResidual] = useState(0);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [sectionsOptions, setSectionsOptions] = useState([]);

  const getSections = useCallback(async () => {
    try {
      const response = await apiRequest(`/getSections/${user?._id}`);
      let sections = response?.sections?.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      sections = sections?.map((section) => ({
        name: section.title,
        id: section._id,
      }));
      setSectionsOptions(sections);
    } catch (e) {
      toast({
        title: "حدث خطأ أثناء جلب الأقسام",
        description: e?.response?.data?.msg || e.message,
        status: "error",
        duration: 3000,
      });
    }
  }, [user?._id, toast]);

  useEffect(() => {
    getSections();
  }, [getSections]);

  // Handel Buttons Status
  const getStatus = useCallback(async () => {
    try {
      setIsBotWorkLoading(true);

      const response = await apiRequest(`/whtsapp/getBotStatus/${user?._id}`);
      if (response.code === 200) {
        setIsBotWork(response?.isWork);
        setResidual(response?.tasks);
      } else {
        toast({
          title: "مشكله",
          description: "حدث مشكله برجاء الاتصال بالمطور",
          status: "error",
          duration: 5000,
        });
      }
    } catch (error) {
      toast({
        title: "مشكله",
        description: "حدث مشكله برجاء الاتصال بالمطور",
        status: "error",
        duration: 5000,
      });
    } finally {
      setIsBotWorkLoading(false);
    }
  }, []);

  useEffect(() => {
    getStatus();
  }, [getStatus]);

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle checkbox change
  const handleCheckboxChange = (e) => {
    setFormData({ ...formData, deleteAfterSend: e.target.checked });
  };

  // Handle image file change
  const handleImageChange = (e) => {
    setFormData({ ...formData, messageImage: e.target.files[0] });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Form validation and submission logic here
    if (!formData.sectionId || !formData.message || !formData.delay) {
      toast({
        title: "خطأ",
        description: "يرجي ملئ جميع الحقول",
        status: "error",
        duration: 5000,
        position: "bottom-right",
      });
      return;
    }

    // Prepare the form data
    const data = new FormData();
    data.append("sectionId", formData.sectionId);
    data.append("message", formData.message);
    formData.messageImage && data.append("messageImage", formData.messageImage);
    formData.messageUrl && data.append("messageUrl", formData.messageUrl);
    data.append("delay", formData.delay);
    data.append("deleteAfterSend", formData.deleteAfterSend);
    data.append("userId", user?._id);

    try {
      setSubmitLoading(true);
      const response = await apiRequest(`/whtsapp/sendMessages`, "POST", data);

      if (response.code === 200) {
        await getStatus();

        toast({
          title: "نجاح",
          description: "تم تشغيل البرنامج بنجاح",
          status: "success",
          duration: 5000,
          position: "bottom-right",
        });
      }

      // Reset form after submission if needed
      setFormData({
        sectionId: "",
        message: "",
        messageImage: null,
        messageUrl: "",
        delay: 0,
        deleteAfterSend: false,
      });
    } catch (error) {
      console.error("Error:", error);
      toast({
        title: "خطأ",
        description: "حدث خطأ أثناء إرسال الرسالة",
        status: "error",
        duration: 5000,
        position: "bottom-right",
      });
    } finally {
      setSubmitLoading(false);
    }
  };

  // stopBot
  const stopBot = async () => {
    try {
      setIsBotStopLoading(true);

      const response = await apiRequest(`/whtsapp/stopBot/${user?._id}`);
      if (response.code === 200) {
        await getStatus();

        toast({
          title: "تم",
          description: "تم ايقاف عمل البرنامج",
          status: "success",
          duration: 5000,
        });
      } else {
        toast({
          title: "مشكله",
          description: "حدث مشكله برجاء الاتصال بالمطور",
          status: "error",
          duration: 5000,
        });
      }
    } catch (error) {
      toast({
        title: "مشكله",
        description: "حدث مشكله برجاء الاتصال بالمطور",
        status: "error",
        duration: 5000,
      });
    } finally {
      setIsBotStopLoading(false);
    }
  };

  // io handelar
  useEffect(() => {
    socket.on("bot_status", async (status) => {
      const isWork = status?.isWork;
      const residual = status?.tasks?.residual;

      setIsBotWork(isWork);
      setResidual(residual);
    });

    // Clean up the listener when the component unmounts
    return () => {
      socket.off("bot_status");
    };
  }, []);

  return (
    <Card mb={{ base: "0px", lg: "20px" }} px={10} pb={8}>
      <form onSubmit={handleSubmit}>
        <SimpleGrid columns={{ md: 2 }} columnGap={10}>
          {/* Select Section */}
          <FormControl mb="20px">
            <FormLabel>حدد القسم المرسل إليه</FormLabel>
            <Select
              dir="rtl"
              placeholder="اختر القسم"
              icon={<></>}
              name="sectionId"
              value={formData.sectionId}
              onChange={handleChange}
            >
              {sectionsOptions?.map((sectionOption, idx) => (
                <option key={idx} value={sectionOption?.id}>
                  {sectionOption?.name}
                </option>
              ))}
            </Select>
          </FormControl>

          {/* Delay */}
          <FormControl mb="20px">
            <FormLabel>وقت الانتظار بين الرسائل (بالثواني)</FormLabel>
            <Input
              type="number"
              placeholder="أدخل عدد الثواني"
              name="delay"
              min={2}
              max={30}
              value={formData.delay}
              onChange={handleChange}
            />
          </FormControl>
        </SimpleGrid>

        {/* Text Area for Message */}
        <FormControl mb="20px">
          <FormLabel>اكتب نص الرسالة</FormLabel>
          <Textarea
            dir="rtl"
            rows={12}
            maxLength={1500}
            resize={"none"}
            placeholder="اكتب رسالتك هنا..."
            name="message"
            value={formData.message}
            onChange={handleChange}
          />
        </FormControl>

        {/* Image Upload */}
        <FormControl mb="20px">
          <FormLabel>تحميل صورة للرسالة</FormLabel>
          <Input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            border="none"
            p="2"
            cursor="pointer"
          />
        </FormControl>

        {/* URL Input */}
        <FormControl mb="20px">
          <FormLabel>رابط للرسالة</FormLabel>
          <Input
            type="url"
            placeholder="https://example.com"
            name="messageUrl"
            value={formData.messageUrl}
            onChange={handleChange}
          />
        </FormControl>

        <FormControl mb={10} mt={"10px"}>
          <Checkbox
            isChecked={formData.deleteAfterSend}
            onChange={handleCheckboxChange}
          >
            حذف المحادثه بعد الإرسال
          </Checkbox>
        </FormControl>

        {/* Send Button */}
        {isBotWorkLoading ? (
          <Button
            isLoading={true}
            loadingText="فحص مهام البرنامج"
            colorScheme="yellow"
            w="100%"
            h="50px"
          >
            إرسال
          </Button>
        ) : isBotWork ? (
          <>
            <Text mb={"10px"}>البرنامج مازال يعمل تبقا: {residual} رسائل</Text>

            <Button
              isLoading={isBotStopLoading}
              loadingText="يتم إيقاف البرنامج"
              type="button"
              onClick={stopBot}
              colorScheme="red"
              w="100%"
              h="50px"
            >
              ايقاف البرنامج
            </Button>
          </>
        ) : (
          <Button
            isLoading={submitLoading}
            loadingText="يتم الارسال"
            type="submit"
            colorScheme="blue"
            w="100%"
            h="50px"
          >
            إرسال
          </Button>
        )}
      </form>
    </Card>
  );
};

export default MessageForm;
