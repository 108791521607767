import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Card,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Text,
  useToast,
  Box,
  HStack,
  IconButton,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  SimpleGrid,
  GridItem,
  Spinner,
  Flex,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";

import { DeleteIcon, DownloadIcon } from "@chakra-ui/icons";
import { FaUserPlus } from "react-icons/fa6";
import { useSelector } from "react-redux";
import apiRequest from "utils/api";
import { CircleLoader } from "react-spinners";

const Sections = () => {
  const user = useSelector((state) => state.user?.data);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const toast = useToast();

  const [sections, setSections] = useState([]);
  const [sectionName, setSectionName] = useState("");
  const [fileContent, setFileContent] = useState(null);

  const [loading, setLoading] = useState(false);
  const [addContactsLoading, setAddContactsLoading] = useState(false);
  const [newSctionLoading, setNewSctionLoading] = useState(false);

  const [sectionDelete, setSectionDelete] = useState(null);

  const getSections = useCallback(async () => {
    setLoading(true);
    try {
      const response = await apiRequest(`/getSections/${user?._id}`);
      let sections = response?.sections?.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      sections = sections?.map((section) => ({
        name: section.title,
        id: section._id,
        numbers: section.contacts || [],
      }));
      setSections(sections);
    } catch (e) {
      toast({
        title: "حدث خطأ أثناء جلب الأقسام",
        description: e?.response?.data?.msg || e.message,
        status: "error",
        duration: 3000,
      });
    } finally {
      setLoading(false);
    }
  }, [user?._id, toast]);

  useEffect(() => {
    getSections();
  }, [getSections]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const newNumbers = e.target.result
        .split("\n")
        .map((num) => num.trim())
        .filter((num) => num !== "");
      setFileContent(newNumbers);
    };
    reader.readAsText(file);
  };

  const handleMergeFileChange = async (e, sectionId) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = async (e) => {
      const newNumbers = e.target.result
        .split("\n")
        .map((num) => num.trim())
        .filter((num) => num !== "");

      try {
        setAddContactsLoading(true);
        const response = await apiRequest("/addContacts", "POST", {
          sectionId: sectionId,
          userId: user?._id,
          contacts: newNumbers,
        });

        if (response.code === 200) {
          await getSections();
          toast({
            title: "تم دمج الأرقام بنجاح",
            status: "success",
            duration: 3000,
          });
        } else {
          toast({
            title: "حدث خطأ",
            description: response.msg,
            status: "error",
            duration: 3000,
          });
        }
      } catch (e) {
        toast({
          title: "حدث خطأ",
          description: e?.response?.data?.msg || e.message,
          status: "error",
          duration: 3000,
        });
      } finally {
        setAddContactsLoading(false);
      }
    };
    reader.readAsText(file);
  };

  const handleAddSection = async () => {
    if (sectionName.trim() === "" || !fileContent) {
      toast({
        title: "اسم القسم وملف الأرقام مطلوبان",
        status: "warning",
        duration: 3000,
      });
      return;
    }

    if (sections.find((section) => section.name === sectionName)) {
      toast({
        title: "القسم موجود بالفعل",
        status: "error",
        duration: 3000,
      });
      return;
    }

    try {
      setNewSctionLoading(true);
      const response = await apiRequest("/newSection", "POST", {
        title: sectionName,
        userId: user?._id,
        contacts: fileContent,
      });

      if (response.code === 200) {
        setSectionName("");
        setFileContent(null);

        toast({
          title: "تم إنشاء القسم بنجاح",
          status: "success",
          duration: 3000,
        });
        await getSections();
      } else {
        toast({
          title: "حدث خطأ",
          description: response.msg,
          status: "error",
          duration: 3000,
        });
      }
    } catch (e) {
      toast({
        title: "حدث خطأ",
        description: e?.response?.data?.msg || e.message,
        status: "error",
        duration: 3000,
      });
    } finally {
      setNewSctionLoading(false);
    }
  };

  const handleDeleteSection = async () => {
    if (!sectionDelete) return;

    try {
      setLoading(true);
      const response = await apiRequest(
        `/delSections/${sectionDelete}`,
        "POST"
      );

      if (response.code === 200) {
        toast({
          title: "تم حذف القسم بنجاح",
          status: "info",
          duration: 3000,
        });
        await getSections();
        onClose();
      } else {
        toast({
          title: "حدث خطأ",
          description: response.msg,
          status: "error",
          duration: 3000,
        });
      }
    } catch (e) {
      toast({
        title: "حدث خطأ",
        description: e?.response?.data?.msg || e.message,
        status: "error",
        duration: 3000,
      });
    }
  };

  const handleDownloadNumbers = (section) => {
    const blob = new Blob([section.numbers.join("\n")], { type: "text/plain" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${section.name}_numbers.txt`;
    link.click();
  };

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose();
          setSectionDelete(null);
        }}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              تأكيد الحذف
            </AlertDialogHeader>

            <AlertDialogBody>
              هل أنت متأكد أنك تريد حذف هذا القسم؟
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => {
                  onClose();
                  setSectionDelete(null);
                }}
              >
                إلغاء
              </Button>
              <Button
                colorScheme="red"
                loadingText="يتم الحذف"
                isLoading={loading}
                onClick={handleDeleteSection}
                ml={3}
              >
                حذف
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Card mb={{ base: "0px", lg: "20px" }} px={10}>
        <VStack spacing={4} pt={4} pb={6}>
          <FormControl py={3}>
            <FormLabel>إنشاء قسم جديد</FormLabel>
            <SimpleGrid columns={{ md: 6 }} spacing={4}>
              <GridItem colSpan={{ base: 1, md: 4 }}>
                <Input
                  placeholder="اسم القسم"
                  value={sectionName}
                  width={"100%"}
                  onChange={(e) => setSectionName(e.target.value)}
                />
              </GridItem>
              <Input
                type="file"
                accept=".txt"
                onChange={handleFileChange}
                display="none"
                id="file-upload"
              />
              <Button
                as="label"
                htmlFor="file-upload"
                colorScheme="teal"
                cursor={"pointer"}
              >
                رفع ملف الأرقام
              </Button>
              <Button
                isLoading={newSctionLoading}
                loadingText="يتم إنشاء القسم"
                colorScheme="blue"
                onClick={handleAddSection}
              >
                إضافة
              </Button>
            </SimpleGrid>
          </FormControl>

          <Box w="100%">
            <Text fontWeight="bold" mb={4}>
              الأقسام
            </Text>
            <Box w="100%" overflowX="auto" maxHeight="400px" overflowY="auto">
              {loading ? (
                <Flex direction="column" justify="center" align="center">
                  <CircleLoader color="#7e4699" />
                  <Text
                    mt={4}
                    fontSize={"24px"}
                    fontWeight="bold"
                    textShadow="0px 2px 4px rgba(0, 0, 0, 0.3)"
                  >
                    جاري التحميل...
                  </Text>
                </Flex>
              ) : sections.length === 0 ? (
                <Text textAlign="center" color="gray.500" py={4}>
                  لا توجد أقسام حاليًا. يمكنك إضافة قسم جديد باستخدام النموذج
                  أعلاه.
                </Text>
              ) : (
                <Table variant="striped" colorScheme="gray">
                  <Thead>
                    <Tr>
                      <Th textAlign={"center"}>اسم القسم</Th>
                      <Th textAlign={"center"}>عدد الأرقام</Th>
                      <Th textAlign={"center"}>العمليات</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {sections.map((section) => (
                      <Tr key={section.id}>
                        <Td fontWeight="bold" textAlign={"center"}>
                          {section.name}
                        </Td>
                        <Td color="gray.500" textAlign={"center"}>
                          {section.numbers.length}
                        </Td>
                        <Td textAlign={"center"}>
                          <HStack
                            spacing={3}
                            display={"flex"}
                            justifyContent={"center"}
                            align={"center"}
                          >
                            <Button
                              size="sm"
                              colorScheme="green"
                              onClick={() => handleDownloadNumbers(section)}
                              leftIcon={<DownloadIcon />}
                            >
                              تحميل الأرقام
                            </Button>
                            <Input
                              type="file"
                              accept=".txt"
                              onChange={(e) =>
                                handleMergeFileChange(e, section.id)
                              }
                              display="none"
                              id={`upload-merge-${section.id}`}
                            />
                            <Button
                              as="label"
                              htmlFor={`upload-merge-${section.id}`}
                              size="sm"
                              colorScheme="teal"
                              cursor={"pointer"}
                              leftIcon={<FaUserPlus />}
                              isLoading={addContactsLoading}
                              loadingText={"يتم الإضافه"}
                            >
                              اضف ارقام
                            </Button>
                            <IconButton
                              icon={<DeleteIcon />}
                              colorScheme="red"
                              size="sm"
                              onClick={() => {
                                setSectionDelete(section?.id);
                                onOpen();
                              }}
                            />
                          </HStack>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              )}
            </Box>
          </Box>
        </VStack>
      </Card>
    </>
  );
};

export default Sections;
