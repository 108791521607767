// Chakra imports
import { Container, Flex } from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
// import FixedPlugin from "components/fixedPlugin/FixedPlugin";

function AuthIllustration(props) {
  const { children } = props;
  // Chakra color mode
  return (
    <Container mt={10} position="relative" h="max-content">
      <Flex w="100%" mx="auto" justifyContent="center" direction="column">
        {children}
      </Flex>
      {/* <FixedPlugin /> */}
    </Container>
  );
}
// PROPS

AuthIllustration.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
};

export default AuthIllustration;
