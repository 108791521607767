import React from "react";

// Chakra imports
import { Box, Icon, SimpleGrid, useColorModeValue } from "@chakra-ui/react";

// Custom components
import { MdAttachMoney, MdOutlinePendingActions } from "react-icons/md";
import { GrSend } from "react-icons/gr";
import { FaBoxesStacked } from "react-icons/fa6";
import { RiContactsBook2Fill } from "react-icons/ri";
import { FaUsers } from "react-icons/fa";

import IconBox from "components/icons/IconBox";
import MiniStatistics from "components/card/MiniStatistics";
import DailyTraffic from "views/admin/rtl/components/DailyTraffic";
import TotalSpent from "views/admin/rtl/components/TotalSpent";

const Home = () => {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="25px" h="25px" as={GrSend} color={brandColor} />}
            />
          }
          name="الرسائل المرسله"
          value="55"
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="28px" h="28px" as={FaUsers} color={brandColor} />}
            />
          }
          name="الارقام المستخرجه"
          value="9,545"
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon
                  w="28px"
                  h="28px"
                  as={RiContactsBook2Fill}
                  color={brandColor}
                />
              }
            />
          }
          name="الارقام المحفوظه"
          value="55,222"
        />
      </SimpleGrid>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2, "2xl": 6 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon
                  w="25px"
                  h="25px"
                  as={FaBoxesStacked}
                  color={brandColor}
                />
              }
            />
          }
          name="الاقسام"
          value="9"
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon
                  w="28px"
                  h="28px"
                  as={MdOutlinePendingActions}
                  color={brandColor}
                />
              }
            />
          }
          name="الرسائل المعلقه"
          value="81"
        />
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
        <TotalSpent />
        <DailyTraffic />
      </SimpleGrid>
    </Box>
  );
};

export default Home;
