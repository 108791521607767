import React from "react";

// components
import { Box } from "@chakra-ui/react";
import Banner from "views/admin/profile/components/Banner";

// Assets
import banner from "assets/img/auth/banner.png";
import avatar from "assets/img/avatars/avatar7.png";
import CollapsibleCard from "components/CollapsibleCard";
import VerifyMark from "components/VerifyMark";
import MessageForm from "components/MessageForm";
import { useSelector } from "react-redux";

const Messages = () => {
  const user = useSelector((state) => state.user?.data);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {user?.isWhtsappAuth !== null && user?.isWhtsappAuth !== undefined ? (
        <>
          {/* Account */}
          <CollapsibleCard
            title="الحساب"
            LeftIcon={<VerifyMark size={35} verify={user?.isWhtsappAuth} />}
            isOpen={!user?.isWhtsappAuth}
          >
            <Banner
              banner={banner}
              avatar={avatar}
              name={
                user?.isWhtsappAuth
                  ? `${user?.firstName} ${user?.lastName}`
                  : "مستخدم"
              }
              phone={user?.isWhtsappAuth ? user?.phone : "xxxxxxxxxxx"}
              isWhtsappAuth={user?.isWhtsappAuth}
            />
          </CollapsibleCard>

          <Box my={8} />
          {/* Message */}
          <CollapsibleCard
            disabled={!user?.isWhtsappAuth}
            disabledMsg={!user?.isWhtsappAuth ? "يجب تسجيل حساب للمتابعة" : ""}
            isOpen={user?.isWhtsappAuth}
            title="إنشاء رسالة"
          >
            <MessageForm />
          </CollapsibleCard>
        </>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default Messages;
