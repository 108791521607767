// Chakra imports
import React, { useEffect, useState } from "react";

import Card from "components/card/Card.js";

import {
  Avatar,
  Box,
  Button,
  Flex,
  SimpleGrid,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import defaultAvatar from "assets/img/avatars/default.png";

import { MdOutlineDeleteForever } from "react-icons/md";
import { FaWhatsapp } from "react-icons/fa6";
import VerifyMark from "components/VerifyMark";
import apiRequest from "utils/api";
import { useDispatch, useSelector } from "react-redux";
import QRCodeModal from "components/QRCodeModal";
import { socket } from "../../../../socket";
import { setUserData } from "state/userSlice";

export default function Banner(props) {
  const user = useSelector((state) => state.user?.data);
  const { banner, avatar, name, phone, isWhtsappAuth } = props;

  const [loading, setLoading] = useState(false);
  const [qrCode, setQrCode] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();
  const toast = useToast();

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );

  const openQrCode = async () => {
    setLoading(true);

    try {
      const response = await apiRequest(`/whtsapp/qrScan/${user?._id}`);
      if (response.code === 200) {
        setQrCode(response.qr);
        setIsOpen(true);
      } else {
        toast({
          title: "Error",
          description: "Failed to fetch QR code.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred while fetching the QR code.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const deleteAccount = async () => {
    setLoading(true);
    const token = localStorage.getItem("token");

    try {
      const response = await apiRequest(`/whtsapp/logout/${user?._id}`);
      if (response.code === 200) {
        try {
          const data = await apiRequest(`/user/${token}`);
          if (data.code === 200) {
            dispatch(setUserData(data?.user));
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        toast({
          title: "Error",
          description: "Failed to fetch QR code.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred while fetching the QR code.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    socket.on("qrImage", (newQrCode) => {
      if (isOpen) {
        setQrCode(newQrCode?.qrcode);
      }
    });

    // Clean up the listener when the component unmounts
    return () => {
      socket.off("qrImage");
    };
  }, []);

  return (
    <>
      <QRCodeModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        qr={qrCode}
      />

      <Card mb={{ base: "0px", lg: "20px" }} align="center">
        <Box
          bg={`url(${banner})`}
          bgSize="cover"
          borderRadius="16px"
          h="121px"
          w="100%"
        />
        <Avatar
          mx="auto"
          src={avatar || defaultAvatar}
          h="120px"
          w="120px"
          mt="-43px"
          border="4px solid"
          borderColor={borderColor}
        />
        <Flex mt="10px" justify={"center"} align={"center"}>
          <Text color={textColorPrimary} me={2} fontWeight="bold" fontSize="xl">
            {name || "لم تسجل حسابك بعد"}
          </Text>
          <VerifyMark size={15} verify={isWhtsappAuth} />
        </Flex>
        <Text color={textColorSecondary} fontSize="sm">
          {phone || "لا رقم هاتف بعد"}
        </Text>
        <Flex w="max-content" mx="auto" mt="10px">
          {isWhtsappAuth ? (
            <SimpleGrid gap="3" column={{ md: 2 }}>
              {/* <Button
              colorScheme="whatsapp"
              leftIcon={<MdBuild />}
              loadingText="تغيير الحساب"
              // isLoading
            >
              تغيير الحساب
            </Button> */}

              <Button
                leftIcon={<MdOutlineDeleteForever size={20} />}
                colorScheme="red"
                onClick={deleteAccount}
                bg="red.600"
                color="white"
                _hover={{ bg: "red.600" }}
                _active={{ bg: "red.700" }}
                loadingText="حذف الحساب"
                isLoading={loading}
              >
                حذف الحساب
              </Button>
            </SimpleGrid>
          ) : (
            <Button
              onClick={openQrCode}
              colorScheme="whatsapp"
              leftIcon={<FaWhatsapp />}
              loadingText="ربط حسابك"
              isLoading={loading}
            >
              ربط حسابك
            </Button>
          )}
        </Flex>
      </Card>
    </>
  );
}
