import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  FormControl,
  FormLabel,
  Textarea,
  VStack,
  Text,
  useToast,
  Box,
  Image,
  Checkbox,
  HStack,
  Avatar,
  Center,
  Flex,
} from "@chakra-ui/react";
import CollapsibleCard from "components/CollapsibleCard";
import { DownloadIcon } from "@chakra-ui/icons";
import { CircleLoader } from "react-spinners";
import { useSelector } from "react-redux";
import apiRequest from "utils/api";
import { socket } from "../../socket";

const ExportContactsNumber = () => {
  const user = useSelector((state) => state.user?.data);
  const toast = useToast();

  const [isCardOpen, setIsCardOpen] = useState(false);
  const [exportData, setExportData] = useState("");
  const [exportDataLoading, setExportDataLoading] = useState(false);

  const [groupLoading, setGroupLoading] = useState(true);
  const [requestSent, setRequestSent] = useState(false);

  const [groupOptions, setGroupOptions] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);

  // io handelar
  useEffect(() => {
    socket.on("groups", async (status) => {
      const token = localStorage.getItem("token");
      if (status?.userId === token) {
        const groupsOptions = status?.groups?.map((group) => ({
          id: group?.id,
          name: group?.name,
          members: group?.membersCount,
          image: group?.image,
        }));

        setGroupOptions(groupsOptions);
        setGroupLoading(false);
      }
    });

    socket.on("members", async (members) => {
      const token = localStorage.getItem("token");
      if (members?.userId === token) {
        const uniqMembers = [...new Set(members.members)];
        const data = uniqMembers.join("\n");

        setSelectedGroups([]);
        setExportData(data);
        setExportDataLoading(false);
      }
    });

    // Clean up the listener when the component unmounts
    return () => {
      socket.off("groups");
      socket.off("members");
    };
  }, []);

  useEffect(() => {
    const getGroups = async () => {
      try {
        const response = await apiRequest(`/whtsapp/getGroups/${user?._id}`);
        if (response.code === 200) {
          setRequestSent(true);
          toast({
            title: "تم",
            description: "جاري تحميل المجموعات من فضلك لا تغلق الصفحه",
            status: "warning",
            duration: 5000,
          });
        } else {
          toast({
            title: "مشكله",
            description: "فشل جلب المجموعات",
            status: "error",
            duration: 5000,
          });
        }
      } catch (error) {
        toast({
          title: "مشكله",
          description: "فشل جلب المجموعات",
          status: "error",
          duration: 5000,
        });
      }
    };

    if (isCardOpen && !requestSent) {
      getGroups();
    }
  }, [isCardOpen, requestSent]);

  const handleToggleCard = () => {
    setIsCardOpen(!isCardOpen);
  };

  const handleSelectChange = (groupId) => {
    const updatedSelection = selectedGroups.includes(groupId)
      ? selectedGroups.filter((group) => group !== groupId)
      : [...selectedGroups, groupId];

    if (updatedSelection.length <= 5) {
      setSelectedGroups(updatedSelection);
    } else {
      toast({
        title: "تم تجاوز الحد",
        description: "يمكنك اختيار 5 مجموعات كحد أقصى.",
        status: "error",
        duration: 3000,
      });
    }
  };

  const handleExport = async () => {
    if (selectedGroups.length > 0) {
      setExportDataLoading(true);

      try {
        const response = await apiRequest(`/whtsapp/exportUsers`, "POST", {
          userId: user?._id,
          groupIds: selectedGroups,
        });
        if (response.code === 200) {
          toast({
            title: "تم",
            description: "جاري تحميل الارقام من فضلك لا تغلق الصفحه",
            status: "warning",
            duration: 5000,
          });
        } else {
          toast({
            title: "مشكله",
            description: "فشل جلب الارقام",
            status: "error",
            duration: 5000,
          });
          setExportDataLoading(false);
        }
      } catch (error) {
        toast({
          title: "مشكله",
          description: "فشل جلب المجموعات",
          status: "error",
          duration: 5000,
        });
        setExportDataLoading(false);
      }
    } else {
      toast({
        title: "غير مكتمل",
        description: "الرجاء تحديد مجموعة واحدة على الأقل.",
        status: "warning",
        duration: 3000,
      });
    }
  };

  const handleDownload = () => {
    const blob = new Blob([exportData], { type: "text/plain" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "export.txt";
    link.click();
    handleReset();
  };

  const handleReset = () => {
    setSelectedGroups([]);
    setExportData("");
  };

  return (
    <Card mb={{ base: "0px", lg: "20px" }} px={10}>
      <VStack spacing={4} pt={4} pb={6}>
        <CollapsibleCard
          isOpen={isCardOpen}
          onToggle={handleToggleCard}
          title="مجموعاتك"
        >
          <FormControl py={3}>
            <FormLabel>حدد من مجموعاتك</FormLabel>
            <Box h={270} overflowY="auto" w="100%">
              {groupLoading ? (
                <Center h="100%">
                  <Flex direction="column" justify="center" align="center">
                    <CircleLoader color="#7e4699" />
                    <Text
                      mt={4}
                      fontSize={"24px"}
                      fontWeight="bold"
                      textShadow="0px 2px 4px rgba(0, 0, 0, 0.3)"
                    >
                      جاري التحميل...
                    </Text>
                  </Flex>
                </Center>
              ) : !groupOptions.length ? (
                <Center h="100%">
                  <Text fontSize="lg" fontWeight="bold">
                    لا توجد مجموعات لعرضها
                  </Text>
                </Center>
              ) : (
                groupOptions.map((group) => (
                  <HStack
                    key={group.id}
                    spacing={4}
                    py={2}
                    borderBottom="1px solid #eee"
                  >
                    <Checkbox
                      isChecked={selectedGroups.includes(group.id)}
                      onChange={() => handleSelectChange(group.id)}
                    >
                      <HStack spacing={4}>
                        <Avatar
                          src={group.image}
                          boxSize="50px"
                          alt={`${group.name} image`}
                        />
                        <Box>
                          <Text fontWeight="bold">{group.name}</Text>
                          <Text fontSize="sm" color="gray.500">
                            {group.members} عضو
                          </Text>
                        </Box>
                      </HStack>
                    </Checkbox>
                  </HStack>
                ))
              )}
            </Box>
            <Text mt={2} color="gray.500">
              لقد حددت {selectedGroups.length} من اصل 5 مجموعات
            </Text>
          </FormControl>
        </CollapsibleCard>

        <Button
          isLoading={exportDataLoading}
          loadingText="سحب الارقام"
          colorScheme="blue"
          onClick={handleExport}
        >
          سحب الارقام
        </Button>
        <Textarea
          placeholder="Exported data will appear here..."
          value={exportData}
          isReadOnly
          mt={4}
          h={200}
          resize={"none"}
        />

        {exportData !== "" ? (
          <Box textAlign={"start"} pr={5} width={"100%"}>
            <Text fontWeight={"bold"}>
              عدد الارقام:{" "}
              <Text as={"span"}>{exportData?.split("\n")?.length || 0}</Text>
            </Text>
          </Box>
        ) : (
          ""
        )}

        <Button
          colorScheme="green"
          onClick={handleDownload}
          isDisabled={!exportData}
          leftIcon={<DownloadIcon />}
        >
          تحميل
        </Button>
      </VStack>
    </Card>
  );
};

export default ExportContactsNumber;
