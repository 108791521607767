import React from "react";
import { Link } from "react-router-dom";

// Chakra imports
import { Flex, Box } from "@chakra-ui/react";
import brandImg from "../../../assets/img/white-Sendioo.png";

// Custom components
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  return (
    <Flex align="center" direction="column">
      <Box mb={5}>
        <Link to={"/"}>
          <img src={brandImg} />
        </Link>
      </Box>
      <HSeparator mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;
