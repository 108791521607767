import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useColorModeValue,
  Alert,
  AlertIcon,
  useToast,
} from "@chakra-ui/react";
import DefaultAuth from "layouts/auth/Default";
import illustration from "assets/img/auth/auth.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import apiRequest from "../../../utils/api";
import { useSelector } from "react-redux";

function SignIn() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { login } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorBrand = useColorModeValue("brand.500", "white");
  const toast = useToast();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClick = () => setShow(!show);

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      const response = await apiRequest("/login", "POST", data);

      // login ui
      localStorage.setItem("token", response?.user?._id);
      await login();
      navigate("/");
    } catch (error) {
      console.error("Login error:", error);
      toast({
        title: "خطأ",
        description: "فشل تسجيل الدخول. تحقق من رقم الهاتف وكلمة المرور.",
        status: "error",
        duration: 5000,
        position: "bottom-right",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Box border="1px solid #cccccc" background="#f9f9f9" borderRadius="25px">
        <Flex
          w="100%"
          alignItems="center"
          justifyContent="center"
          mb={{ base: "30px", md: "60px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "40px", md: "14vh" }}
          flexDirection="column"
        >
          <Heading
            color={textColor}
            fontSize="36px"
            mb="10px"
            textAlign={"center"}
          >
            تسجيل دخول
          </Heading>
          <Text
            mb="20px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
            textAlign="center"
          >
            أدخل بريدك الإلكتروني وكلمة المرور لتسجيل الدخول
          </Text>
          <Flex
            dir="rtl"
            direction="column"
            w={{ base: "100%", md: "420px" }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx="auto"
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormLabel
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                رقم الهاتف
                <Text
                  ms="10px"
                  color={"#707986"}
                  fontWeight="600"
                  fontSize="sm"
                >
                  مصحوباً بكود الدولة بدون رمز "+"
                </Text>
              </FormLabel>

              <Input
                variant="auth"
                fontSize="sm"
                type="text"
                placeholder="201028076981"
                mb="4px"
                fontWeight="500"
                size="lg"
                border="2.3px solid #797979cc"
                maxLength={12}
                textAlign={"center"}
                {...register("phone", {
                  required: "يجب كتابة رقم الهاتف",
                  maxLength: {
                    value: 12,
                    message: "يجب ان يكون طول رقم الهاتف 12 أو أقل",
                  },
                  minLength: {
                    value: 5,
                    message: "يجب ان يكون طول رقم الهاتف 5 أو أكثر",
                  },
                })}
              />

              {errors.phone && (
                <Alert mb={4} status="error" mt="2" borderRadius="md">
                  <AlertIcon />
                  {errors.phone.message}
                </Alert>
              )}

              <FormLabel
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                كلمة المرور
              </FormLabel>

              <InputGroup size="md">
                <Input
                  pe={10}
                  fontSize="sm"
                  placeholder="الحد الأدنى 8 أحرف"
                  mb="4px"
                  size="lg"
                  type={show ? "text" : "password"}
                  variant="auth"
                  paddingRight={5}
                  border="2.3px solid #797979cc"
                  textAlign={"center"}
                  {...register("password", {
                    required: "كلمة المرور مطلوبة.",
                    minLength: {
                      value: 8,
                      message: "كلمة المرور يجب أن تكون 8 أحرف على الأقل.",
                    },
                  })}
                />

                <InputLeftElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputLeftElement>
              </InputGroup>

              {errors.password && (
                <Alert mb="10px" status="error" mt="2" borderRadius="md">
                  <AlertIcon />
                  {errors.password.message}
                </Alert>
              )}

              <Flex justifyContent="space-between" align="center" mb="24px">
                <Text color={textColorBrand} fontSize="sm" fontWeight="500">
                  نسيت كلمة المرور؟
                </Text>
              </Flex>

              <Button
                isLoading={loading}
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
                type="submit"
              >
                تسجيل الدخول
              </Button>
            </form>

            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="start"
              maxW="100%"
            >
              <Text color={textColorSecondary} fontWeight="400" fontSize="14px">
                لم تسجل بعد؟
                <NavLink to="/auth/sign-up">
                  <Text
                    color={textColorBrand}
                    as="span"
                    ms="5px"
                    fontWeight="500"
                  >
                    إنشاء حساب
                  </Text>
                </NavLink>
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </DefaultAuth>
  );
}

export default SignIn;
