import "./assets/css/App.css";
import AuthLayout from "./layouts/auth";
import Layout from "./layouts/layout";
import initialTheme from "./theme/theme";
import useAuth from "Hooks/useAuth";

import { Routes, Route, Navigate } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { socket } from "./socket";
import LoadingScreen from "Pages/LoadingScreen";

import { useDispatch } from "react-redux";
import { setAuthFunctions, setUserData } from "./state/userSlice";

const App = () => {
  const { isAuthenticated, loading, userData, login, logout } = useAuth("user");
  const dispatch = useDispatch();
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [currentTheme, setCurrentTheme] = useState(initialTheme);

  useEffect(() => {
    const onConnect = () => {
      setIsConnected(true);
    };
    socket.on("connect", onConnect);

    return () => {
      socket.off("connect", onConnect);
    };
  }, []);

  useEffect(() => {
    if (userData) {
      dispatch(setUserData(userData)); // Store the user data in the Redux store
    }
  }, [userData, dispatch]);

  useEffect(() => {
    dispatch(setAuthFunctions({ login, logout }));
  }, [dispatch, setAuthFunctions]);

  if (loading) return <LoadingScreen />;

  return (
    <ChakraProvider theme={currentTheme}>
      <Routes>
        {/* Protected routes */}
        {isAuthenticated ? (
          <>
            <Route
              path="/*"
              element={
                <Layout theme={currentTheme} setTheme={setCurrentTheme} />
              }
            />
            <Route path="auth/*" element={<Navigate to="/" replace />} />
          </>
        ) : (
          <Route path="/*" element={<Navigate to="/auth/sign-in" replace />} />
        )}

        <Route path="/" element={<Navigate to="/home" replace />} />
        <Route path="auth/*" element={<AuthLayout />} />
      </Routes>
    </ChakraProvider>
  );
};

export default App;
