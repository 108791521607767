import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Image,
} from "@chakra-ui/react";

import { socket } from "../../socket";
import apiRequest from "utils/api";
import { useDispatch } from "react-redux";
import { setUserData } from "state/userSlice";

const QRCodeModal = ({ isOpen, onClose, qr }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const whtsappLogin = async () => {
    setLoading(true);

    const token = localStorage.getItem("token");

    if (token) {
      try {
        const data = await apiRequest(`/user/${token}`);
        if (data.code === 200) {
          dispatch(setUserData(data?.user));
        }
      } catch (error) {
        console.log(error);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    socket.on("qrScanned", async (status) => {
      const token = localStorage.getItem("token");
      if (status?.scanned && status?.userId === token) {
        await whtsappLogin();
        onClose();
      }
    });

    // Clean up the listener when the component unmounts
    return () => {
      socket.off("qrScanned");
    };
  }, []);

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader textAlign={"end"}>Scan the QR Code</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {qr ? (
            <Image m={"auto"} src={qr} alt="QR Code" />
          ) : (
            <p>No QR code available</p>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            loadingText="جاري الاتصال"
            isLoading={loading}
            colorScheme={loading ? "green" : "red"}
            m={"auto"}
            onClick={onClose}
          >
            اغلاق
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default QRCodeModal;
