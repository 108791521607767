import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
} from "react-icons/md";
import { AiFillMessage, AiOutlineLogout } from "react-icons/ai";
import { IoMdContact } from "react-icons/io";

// Pages
import Home from "./Pages/Home";
import Messages from "./Pages/Messages";
import Sections from "./Pages/Sections";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import RTL from "views/admin/rtl";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import SignUp from "views/auth/signup";

const routes = [
  {
    name: "الصفحة الرئيسية",
    layout: "/rtl",
    path: "/home",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <Home />,
  },
  {
    name: "الرسائل",
    layout: "/rtl",
    path: "/messages",
    icon: (
      <Icon as={AiFillMessage} width="20px" height="20px" color="inherit" />
    ),
    component: <Messages />,
  },
  {
    name: "الارقام والاقسام",
    layout: "/rtl",
    path: "/sections",
    icon: <Icon as={IoMdContact} width="20px" height="20px" color="inherit" />,
    component: <Sections />,
  },
  {
    name: "تسجيل الدخول",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: <SignInCentered />,
  },
  {
    name: "تسجيل حساب",
    layout: "/auth",
    path: "/sign-up",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: <SignUp />,
  },
];

export default routes;
