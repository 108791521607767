import React from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

import defaultAvatar from "../../../assets/img/avatars/default.png";

function SignUp() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Box border="1px solid #cccccc" background="#f9f9f9" borderRadius="25px">
        <Flex
          w="100%"
          me="auto"
          h="100%"
          alignItems="center"
          justifyContent="center"
          mb={{ base: "30px", md: "60px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "40px", md: "14vh" }}
          flexDirection="column"
        >
          <Box m="auto">
            <Heading
              color={textColor}
              fontSize="36px"
              mb="40px"
              textAlign={"center"}
            >
              إنشاء حساب
            </Heading>
          </Box>

          <Flex
            dir="rtl"
            zIndex="2"
            direction="column"
            w={{ base: "100%", md: "420px" }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: "auto", lg: "unset" }}
            me="auto"
            mb={{ base: "20px", md: "auto" }}
          >
            <FormControl>
              {/* Avatar */}
              <Box textAlign={"center"} mb={10}>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="xl"
                  fontWeight="bold"
                  color={textColor}
                  mb="8px"
                >
                  الصورة الشخصية
                </FormLabel>

                <Avatar
                  cursor={"pointer"}
                  src={defaultAvatar}
                  alt="error"
                  w={130}
                  h={130}
                />
              </Box>

              {/* Name */}
              <Flex justifyContent="space-between">
                <Box>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    الاسم الاول
                  </FormLabel>
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: "0px", md: "0px" }}
                    type="email"
                    placeholder="محمد"
                    mb="24px"
                    fontWeight="500"
                    size="lg"
                    textAlign={"center"}
                    border="2.3px solid #797979cc"
                  />
                </Box>

                <Box>
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    الاسم الاخير
                  </FormLabel>
                  <Input
                    isRequired={true}
                    variant="auth"
                    fontSize="sm"
                    ms={{ base: "0px", md: "0px" }}
                    type="email"
                    placeholder="اشرف"
                    mb="24px"
                    fontWeight="500"
                    textAlign={"center"}
                    size="lg"
                    border="2.3px solid #797979cc"
                  />
                </Box>
              </Flex>

              {/* Email */}
              <Box>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  الإيميل
                </FormLabel>
                <Input
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="email"
                  placeholder="mail@simmmple.com"
                  mb="24px"
                  fontWeight="500"
                  textAlign={"center"}
                  size="lg"
                  border="2.3px solid #797979cc"
                />
              </Box>

              {/* Phone */}
              <Box>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  رقم الهاتف
                  <Text
                    mb="20px"
                    ms="10px"
                    color={"#707986"}
                    fontWeight="600"
                    fontSize="sm"
                  >
                    مصحوباً بكود الدوله بدون رمز "+"
                  </Text>
                </FormLabel>
                <Input
                  isRequired={true}
                  variant="auth"
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="email"
                  placeholder="201028076981"
                  textAlign={"center"}
                  mb="24px"
                  fontWeight="500"
                  size="lg"
                  border="2.3px solid #797979cc"
                />
              </Box>

              {/* password */}
              <Box>
                <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                >
                  كلمة المرور
                </FormLabel>
                <InputGroup size="md">
                  <Input
                    pe={10}
                    isRequired={true}
                    fontSize="sm"
                    placeholder="الحد الأدنى 8 أحرف"
                    mb="24px"
                    size="lg"
                    type={show ? "text" : "password"}
                    variant="auth"
                    paddingRight={5}
                    textAlign={"center"}
                    border="2.3px solid #797979cc"
                  />
                  <InputLeftElement display="flex" alignItems="center" mt="4px">
                    <Icon
                      color={textColorSecondary}
                      _hover={{ cursor: "pointer" }}
                      as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                      onClick={handleClick}
                    />
                  </InputLeftElement>
                </InputGroup>
              </Box>

              {/* Re password */}
              <Box>
                <FormLabel
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  display="flex"
                >
                  إعادة كلمة المرور
                </FormLabel>
                <InputGroup size="md">
                  <Input
                    pe={10}
                    isRequired={true}
                    fontSize="sm"
                    placeholder="اعد كتابة كلمة المرور"
                    mb="24px"
                    size="lg"
                    type={show ? "text" : "password"}
                    variant="auth"
                    paddingRight={5}
                    textAlign={"center"}
                    border="2.3px solid #797979cc"
                  />
                  <InputLeftElement display="flex" alignItems="center" mt="4px">
                    <Icon
                      color={textColorSecondary}
                      _hover={{ cursor: "pointer" }}
                      as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                      onClick={handleClick}
                    />
                  </InputLeftElement>
                </InputGroup>
              </Box>

              {/* Submit */}
              <Flex justifyContent="space-between" align="center" mb="10px">
                <Box />
              </Flex>
              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                mb="24px"
              >
                تسحيل الحساب
              </Button>
            </FormControl>

            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="start"
              maxW="100%"
              mt="0px"
            >
              <Text color={textColorDetails} fontWeight="400" fontSize="14px">
                الديك حساب بالفعل ؟
                <NavLink to="/auth/sign-in">
                  <Text
                    color={textColorBrand}
                    as="span"
                    ms="5px"
                    fontWeight="500"
                  >
                    تسجيل الدخول
                  </Text>
                </NavLink>
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </DefaultAuth>
  );
}

export default SignUp;
