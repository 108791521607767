import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    isAuthenticated: false,
    data: null,
    login: null,
    logout: null,
  },
  reducers: {
    setUserData: (state, action) => {
      state.isAuthenticated = true;
      state.data = action.payload;
    },
    clearUserData: (state) => {
      state.isAuthenticated = false;
      state.data = null;
    },
    setAuthFunctions: (state, action) => {
      state.login = action.payload.login;
      state.logout = action.payload.logout;
    },
  },
});

export const { setUserData, clearUserData, setAuthFunctions } =
  userSlice.actions;

export default userSlice.reducer;
