import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import { CircleLoader } from "react-spinners";

const LoadingScreen = () => {
  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      height="100vh"
      position="fixed"
      inset={0}
      bgColor="#7e4699"
    >
      <CircleLoader color="#fff" />
      <Text
        mt={20}
        color="white"
        fontSize={"24px"}
        fontWeight="bold"
        textShadow="0px 2px 4px rgba(0, 0, 0, 0.3)"
      >
        ...جاري التحميل
      </Text>
    </Flex>
  );
};

export default LoadingScreen;
