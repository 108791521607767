import React from "react";

// components
import { Box } from "@chakra-ui/react";

// Assets
import CollapsibleCard from "components/CollapsibleCard";
import ExportContactsNumber from "components/ExportContactsNumber";
import SectionsComponents from "components/SectionsComponents";
import { useSelector } from "react-redux";

const Sections = () => {
  const user = useSelector((state) => state.user?.data);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {user?.isWhtsappAuth !== null && user?.isWhtsappAuth !== undefined ? (
        <>
          {/* Account */}
          <CollapsibleCard
            isOpen={user?.isWhtsappAuth}
            disabled={!user?.isWhtsappAuth}
            disabledMsg={
              !user?.isWhtsappAuth ? "يجب تسجيل حساب واتساب للمتابعة" : ""
            }
            title="سحب الارقام من المجموعات"
          >
            <ExportContactsNumber />
          </CollapsibleCard>

          {/* Message */}
          <CollapsibleCard isOpen={!user?.isWhtsappAuth} title="الاقسام">
            <SectionsComponents />
          </CollapsibleCard>
        </>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default Sections;
